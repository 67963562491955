@import '../bootstrap/variable.scss';

.pagination{
    justify-content: flex-end;
    .sr-only{
        display: none;
    }
    .page-link{
        margin-left: 10px !important;
        border-radius: 5px;
        border: 1px solid $border
    }

    .page-item {
        padding: 10px 0px;
    }
    
    span{
        align-self: center;
        padding:0px 11px 0px 11px;
    }
}