//Import Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~react-intl-tel-input/dist/main.css";

//Bootstrap(Pre load Variables and other things)
@import "./bootstrap/bootstrap.scss";

//Layout
@import "./layout/layout.scss";

//Common
@import "./common/common.scss";

//Pages
@import "./pages/pages.scss";

.header {
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
}

.alignContentCenter {
  align-self: center;
}

.sub-header {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
}

.sub-header-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: $sub-header-text;
}

/* intl tel input override*/

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 5px;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  display: flex;
}

.intl-tel-input {
  width: 100%;
}
