$primary-color:#2C3B70;
$secondary-color:#F4F6F8;
$text-color:#060F30;
$sidebar-width:88px;
$mainpage-left:88px;
$sidebar-font-size:2rem;
$sidebar-item-hover:#365099;
$white: #ffffff;
$border: #D7DCE5;
$sub-header-text: #697089;

