@media only screen and (max-width: 768px) {
    .mainpage {
        left:0px !important;
        transition: all 1s;

        h3{
            font-size: 30px !important;
            font-weight: 400;
            margin-bottom: 0px !important;
            margin-left: 12px !important;
        }

        h5{
            font-size: 20px !important;
        }  
        
        .content {
            margin-left: 0px !important;
        }
    }

    .breadcrumb{
        margin-left: 12px !important;
    }



    .mainpage__transform  {
        left: 30%;
        width: 70%;
    }


    .flex-column.active-sidebar {
        width: 30% !important;
    }

    .dashboard-header {
        top: 75% !important;
        left: 55% !important;
    }

    .extra-content {
        padding-left:12px;
    }
    
    .border-line {
        width: 100%;
    }

    // showing sidebar icon for mobile design's
    .mobile-sidebar {
        display: block;
        position: absolute;
        z-index: 100;
        transition: all 1s;
        padding: 10px;
    }

    .breadcrumbs {
        margin-top: 50px;
    }

    .marginBottom {
        margin-bottom: 10px;
    }

    .sticky-header {
        position: fixed;
        width: 100%;
        background: $white;
        z-index: 10000;
    }
}
@media only screen and (max-width: 500px) {
    .mainpage {
        h3{
            font-size: 20px;
        }
        h5{
            font-size: 20px;
        }
    }

    .marginBottom {
        margin-bottom: 10px;
    }

    .extra-content {
        padding-left:12px;
    }

    .btn-primary{
        font-size: 11px;
    }
    .btn-secondary{
        font-size: 11px;
    }
    td{
        font-size: 10px;
    }
    th{
        font-size: 10px;
    }

    .content {
        margin-left: 0px !important;
    }

    .mainpage__transform  {
        left: 100%;
    }

    .flex-column.active-sidebar {
        width: 100% !important;
    }
}



@media only screen and (max-width: 360px) { 
    .dashboard-header {
        top: 94% !important
    }
    .mainpage {
        h3{
            font-size: 20px !important;
            font-weight: 400;
            margin-bottom: 2px !important;
            margin-left: 12px !important;
        }
    }
    
}