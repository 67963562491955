.table {
    border-collapse: separate;
    border-spacing: 0 15px;

}


.table__th--bgcolor {
    background-color: #e5e5e5;
    color: #5f667d;
}


.transform-arrow {
    transform: rotate(180deg);
}

.transition-arrow {
    transition: transform 0.4s ease 0s;
}

.pointer {
    cursor: pointer;
}
.svg-s25{
    width: 25px;
    height: 25px;
}